<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/jishu/wenti_banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div style="margin-top:5%">
                <el-form ref="form" :model="faqParams"  label-width="0px" @submit.native.prevent>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="" prop="fmi">
                                <el-input v-model.trim="faqParams.faqs_ques" @keyup.enter.native="search">
                                    <template slot="prepend"><el-button slot="append" icon="el-icon-search"></el-button></template>
                                    <el-button slot="append" style="background: #0052D9;color:#ffffff;border-radius:0" @click="search">{{$t('inquire')}}</el-button>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="list" v-if="list !==undefined && list != null  && list.length > 0">
                <div class="tablist">
                    <el-collapse :accordion='true'>
                        <el-collapse-item v-for="(item,index) in list" :key="index" :title="item.faqs_title" :name="item.faqs_id">
                            <div>{{$t('wenti.problemDescription')}}：</div>
                            <div v-html="item.faqs_ques"></div>
                            <div>{{$t('wenti.questionAnswering')}}：</div>
                            <div v-html="item.faqs_ans"></div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <el-pagination
                    v-if="total>faqParams.limit"
                    style="margin-top:3%;text-align:center"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="faqParams.page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="faqParams.limit"
                    layout="total, prev, pager, next"
                    :pager-count="5"
                    :total="total">
                </el-pagination>
            </div>
            <div v-if="list == undefined ||list == null || list.length <= 0">
                <div class="empty">
                    <el-image :src="require('@/assets/jishu/empty.png')"></el-image>
                </div>
                <div class="emptytitle">{{$t('notAvailable')}}</div>
                <div class="emptytitle">{{$t('nosousuo')}}，<span style="color:#0052D9;cursor: pointer;" @click="nav('feedback')">{{$t('BUG')}}</span></div>
            </div>
        </div>
    </div>
</template>
<script>
import { faqList} from "@/api/api"; 
export default {
    data(){
        return{
            list:[],
            faqParams:{
                page:1,
                limit:10
            },
            total:0,
            title:''
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    created(){
        this.getList()
    },
    methods:{
        async getList(){
            const{data:res} = await faqList(this.faqParams)
            this.total = res.total
            this.list = res.list
        },
        search(){
            this.faqParams.page = 1
            this.getList()
        },
        handleSizeChange(value){
            this.faqParams.limit = value
            this.getList()
        },
        handleCurrentChange(value){
            this.faqParams.page = value
            this.getList()
        },
        nav(url){
            this.$router.push('/'+url,'_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 60%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
    .empty{
        width: 100px;
        margin: 0 auto;
        margin-top: 8%;
    }
    .emptytitle{
        text-align: center;
        margin-top: 10px;
    }
    .tablist{
        padding-left: 5%;
        background: #fff;
        margin-top: 20px;
    }
</style>
<style scoped>
    .tablist >>> .el-collapse-item__header{
        font-size: 18px;
        font-weight: 550;
        color: #333333;
        height: 70px;
        font-weight: bold;
    }
    .tablist >>> .el-collapse-item__content{
        padding-top: 3%;
        padding-left: 3%;
        font-size: 17px;
        color: #333333;
    }
    .tablist >>> .el-collapse-item__wrap{
        background: #F6F6F6;
        margin-right: 5%;
    }
</style>